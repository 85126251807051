.bubble{
    width: "80px";
    height: "80px";
    border-radius: "50%";
    border: "4px solid #d51c29";
    position: "absolute";
    text-align: "center";
    line-height: "70px";
    background: "white";
    cursor: "pointer";
    box-shadow: "0px 0px 6px #B2B2B2";
}

.bubble.active{
    color: "white" !important;
    background: "#d51c29" !important;
}

.arrows, .central{
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: "0px 0px 6px silver";
}

.arrows>div, .central>div {
    text-align: center;
    width: 50px;
    vertical-align: middle;
    margin-top: 50%;
    transform: translateY(-25%);
    position: absolute;
}

.arrows::before{
    cursor: pointer;
    content: "";
    position: absolute;
    border: 6px solid transparent;
    width: 0px;
    height: 0px;
    box-shadow: "0px 0px 6px silver";
}

.arrows.active{
    background-color: green !important;
    background: green !important;
}

.arrow-up::before{
    border-bottom: 6px solid #d51c29;
    margin-top: -12px;
    margin-left: -3px;
}

.arrow-up.active::before{
    border-bottom: 6px solid green !important;
}

.arrow-up>div{
    margin-left: -20px;
    margin-top: -24px;
    background-color: tomato;
}

.active.arrows > div {
    background-color: green !important;
}

.arrow-right::before{
    border-left: 6px solid #d51c29;
    margin-left: 40px;
    margin-top: -3px;
}

.arrow-right.active::before{
    border-left: 6px solid green !important;
}

.arrow-right>div{
    margin-left: 46px;
    margin-top: -4px;
    background-color: tomato;
}

.arrow-down::before{
    border-top: 6px solid #d51c29;
    margin-top: 40px;
    margin-left: -3px;
}

.arrow-down.active::before{
    border-top: 6px solid green !important;
}

.arrow-down>div{
    margin-left: -20px;
    margin-top: 52px;
    background-color: tomato;
}

.arrow-left::before{
    border-right: 6px solid #d51c29;
    margin-left: -12px;
    margin-top: -3px;
}

.arrow-left.active::before{
    border-right: 6px solid green !important;
}

.arrow-left>div{
    margin-left: -56px;
    margin-top: -4px;
    background-color: tomato;
}

/* 
.bubble{
    width: "80px";
    height: "80px";
    border-radius: "50%";
    border: "4px solid #d51c29";
    position: "absolute";
    text-align: "center";
    line-height: "70px";
    background: "white";
    cursor: "pointer";
    box-shadow: "0px 0px 6px #B2B2B2";
}

.bubble.active{
    color: "white" !important;
    background: "#d51c29" !important;
}

.arrows, .central{
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: "0px 0px 6px silver";
}

.arrows>div, .central>div {
    text-align: center;
    height: 50px;
    vertical-align: middle;
    margin-top: 50%;
    transform: translateY(-25%);
}

.arrows::before{
    cursor: pointer;
    content: "";
    position: absolute;
    border: 30px solid transparent;
    width: 0px;
    height: 0px;
    box-shadow: "0px 0px 6px silver";
}

.arrow-up::before{
    border-bottom: 30px solid #d51c29;
    margin-top: -60px;
    margin-left: -5px;
}
.arrow-right::before{
    border-left: 30px solid #d51c29;
    margin-left: 50px;
    margin-top: -5px;
}
.arrow-down::before{
    border-top: 30px solid #d51c29;
    margin-top: 50px;
    margin-left: -5px;
}
.arrow-left::before{
    border-right: 30px solid #d51c29;
    margin-left: -60px;
    margin-top: -5px;
} */