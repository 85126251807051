.filter-date > .form-control{
    height: 40px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 37px 9px rgb(0 0 0 / 3%) !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
}

.filter-button{
    height: 40px !important;
    box-shadow: 0px 4px 37px 9px rgba(0, 0, 0, 0.03) !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
}

.filter-button:hover{
    background: #d51c29 !important;
}

.card{
    border-radius: 13px !important;
    box-shadow: 0px 4px 37px 9px rgb(0 0 0 / 3%) !important;
    overflow: hidden !important;
}