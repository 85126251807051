.wrapper {
    padding: 20px;
}

.wrapper h3 {
    text-transform: capitalize;
}

.wrapper form button {
    margin: 10px 0;
    cursor: pointer;
}

.wrapper ul button {
    background: none;
    border: none;
    cursor: pointer;
}

.body, .navbar-glass{
    background-color: "#F9F9F9" !important;
}

.card{
    border-radius: 13px !important;
    box-shadow: 0px 4px 37px 9px rgb(0 0 0 / 3%) !important;
    overflow: hidden !important;
}

.body, input, span, div, p, h1, h2, h3, h4, h5, h6{
    font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
}
/* 
body, .navbar-glass{
    background-color: #F9F9F9 !important;
}

.navbar-vertical{
    background-color: #F4F4F4 !important;
} */



.it{
    fill:rgba(0,0,0,0.3);
    stroke:rgba(0,0,0,0.3);
    stroke-width:0;
    cursor: pointer;
}
.it:hover{
    cursor: pointer;
    fill: rgba(213, 28, 41, 0.5) !important;
}
  
.selected-button.active{
    height: 40px !important;
    box-shadow: 0px 4px 37px 9px rgba(0, 0, 0, 0.03) !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
}

.btn-primary .selected-button.active:hover{
    background: #d51c29 !important;
}

.selected-button{
    height: 40px !important;
    box-shadow: 0px 4px 37px 9px rgba(0, 0, 0, 0.03) !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
}

.selected-button:hover{
    background: #d51c29 !important;
}

a.active, a:hover{
  color: #d51c29 !important;  
}